import React, { Component } from "react";
import API from "../../config";
import { Card, CardBody, Col, Row, Container, FormGroup, Label, Input, Button } from "reactstrap";
import axios from 'axios';

// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

const columns = [
{
    dataField: "id",
    text: "区",
    sort: false
},
{
    dataField: "6",
    text: "1",
    sort: false
},
{
    dataField: "7",
    text: "2",
    sort: false
},
{
    dataField: "1",
    text: "A",
    sort: false
},
{
    dataField: "2",
    text: "B",
    sort: false
},
{
    dataField: "3",
    text: "C",
    sort: false
},
{
    dataField: "4",
    text: "D",
    sort: false
},
{
    dataField: "5",
    text: "E",
    sort: false
},
  {
    dataField: "8",
    text: "VIP",
    sort: false
  },
  {
    dataField: "9",
    text: "F",
    sort: false
  },
  {
    dataField: "10",
    text: "G",
    sort: false
  },
  {
    dataField: "11",
    text: "H",
    sort: false
  },
  {
    dataField: "12",
    text: "I",
    sort: false
  },
  {
    dataField: "13",
    text: "K",
    sort: false
  }
];

function toBlock(block){
    switch(block){
        case "1":
            return "A";
        case "2":
            return "B";
        case "3":
            return "C";
        case "4":
            return "D";
        case "5":
            return "E";
        case "6":
            return "1";
        case "7":
            return "2";
      case "8":
        return "VIP";
      case "9":
        return "F";
      case "10":
        return "G";
      case "11":
        return "H";
      case "12":
        return "I";
      case "13":
        return "K";
    }
}
function toKey(block){
    switch(block){
        case "A":
            return "1";
        case "B":
            return "2";
        case "C":
            return "3";
        case "D":
            return "4";
        case "E":
            return "5";
        case "1":
            return "6";
        case "2":
            return "7";
      case "VIP":
        return "8";
      case "F":
        return "9";
      case "G":
        return "10";
      case "H":
        return "11";
      case "I":
        return "12";
      case "K":
        return "13";
    }
}

const convertConfigToArray = (data)=>{
    let result = [];
    for(let key in data){
        result.push({
            id: toBlock(key),
            ...data[key]
        })
    }
    return  result
}

const convertConfigToObject = (data)=>{
    let result = {};
    for(let i in data){
        let key = toKey(data[i].id.toString());
        delete  data[i].id;
        for(let j in data[i]){
            if(!data[i][j]){
                delete data[i][j];
            }
        }
        result[key] = {
            ...data[i]
        }
    }
    return  result

}

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            systemConfig: [],
            bonusLogic: [],
            bankConfig:[],
            area:'',
            bank:'',
            accountName:'',
            accountNumber:'',
            accountType:'',
            bsb:'',
            qrcode:null,
            adConfig:[],
            shanghuiConfig:[],
            shangxueyuanConfig:[],
            cishanConfig:[],
            adImage:null,
            shFile:null,
            xyFile: null,
            csFile: null,
            xyFileCategory:'',
            csFileCategory:'',
            shFileCategory:'',
            updateAdImage:{},
            updateSHFile:{},
            updateCSFile:{},
            updateXYFile:{},
        };
        this.getConfig.bind(this);
    }

    componentDidMount(){
        this.getConfig();
    }

    getConfig = () => {
        const user = JSON.parse(localStorage.getItem('authUser'));
        const config = {
            headers: { Authorization: `Bearer ${user.token}` }
        };
        axios.get(API+"/config", config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            if(response.data.data){
                this.setState({systemConfig : response.data.data});
                const bankDetail = response.data.data.find(n=>n.name == 'BANK_DETAIL_CONFIG')?.value
                const adDetail = response.data.data.find(n=>n.name == 'AD_CONFIG')?.value
                const shDetail = response.data.data.find(n=>n.name == 'SHANGHUI_CONFIG')?.value
                const xyDetail = response.data.data.find(n=>n.name == 'XUEYUAN_CONFIG')?.value
                const csDetail = response.data.data.find(n=>n.name == 'GONGYI_CONFIG')?.value
                if(bankDetail){
                    this.setState({bankConfig:JSON.parse(bankDetail)})
                }
                if(adDetail){
                    this.setState({adConfig:JSON.parse(adDetail)})
                }
                if(shDetail){
                    this.setState({shanghuiConfig:JSON.parse(shDetail)})
                }
                if(csDetail){
                    this.setState({cishanConfig:JSON.parse(csDetail)})
                }
                if(xyDetail){
                    this.setState({shangxueyuanConfig:JSON.parse(xyDetail)})
                }
            }
        }).catch(err => {
            throw err[1];
        });
    }
    

    render() {
        const handleDeleteBankConfig = (index) =>{
            const updatedConfig = [...this.state.bankConfig];
            updatedConfig.splice(index,1);
            updateConfig('BANK_DETAIL_CONFIG',JSON.stringify(updatedConfig),'-')
    
        }
        const handleDeleteAdConfig = (index) =>{
            const updatedConfig = [...this.state.adConfig];
            updatedConfig.splice(index,1);
            updateConfig('AD_CONFIG',JSON.stringify(updatedConfig),'-')
    
        }

        const handleDeleteFileConfig = (index,type) =>{
            const updatedConfig = type === 'shanghui'? [...this.state.shanghuiConfig]: 'cishan'? [...this.state.cishanConfig]:[...this.state.shangxueyuanConfig];
            updatedConfig.splice(index,1);
            updateConfig(type === 'shanghui'? 'SHANGHUI_CONFIG':'cishan'? "GONGYI_CONFIG":"XUEYUAN_CONFIG",JSON.stringify(updatedConfig),'-')
    
        }
        const handleUpdateAdConfig = async(index) =>{
            const updatedConfig = [...this.state.adConfig];
            const user = JSON.parse(localStorage.getItem('authUser'));
            let adImage = ''
            if(this.state.updateAdImage[index]){
                var data = new FormData();
                data.append('Name', `AD-IMAGE-${new Date().getTime()}`);
                data.append('Images', this.state.updateAdImage[index]);
                data.append('Description', this.state.updateAdImage[index].name);
                const config = {
                    headers: { Authorization: `Bearer ${user.token}` },              
                };
                const response = await axios.post(API+"/Config/media", data, config)
                adImage = response.data.data.value
            }else{
                alert("请添加图片！")
                return
            }
            updatedConfig[index]={
               imageUrl: adImage,
               
            }
            updateConfig('AD_CONFIG',JSON.stringify(updatedConfig),'-')
    
        }
        const handleAddFileConfig = async(type) =>{
            const updatedConfig = type === 'shanghui'? [...this.state.shanghuiConfig]:'cishan'? [...this.state.cishanConfig]:[...this.state.shangxueyuanConfig];
            const user = JSON.parse(localStorage.getItem('authUser'));
            let adImage = ''
            const target =  type === 'shanghui'? this.state.shFile:'cishan'? this.state.csFile: this.state.xyFile
            if(target){
                var data = new FormData();
                data.append('Name', `AD-FILE-${new Date().getTime()}`);
                data.append('Images', target);
                data.append('Description', target.name);
                const config = {
                    headers: { Authorization: `Bearer ${user.token}` },              
                };
                const response = await axios.post(API+"/Config/media", data, config)
                adImage = response.data.data.value
            }
            updatedConfig.push({
               imageUrl: adImage,
               name: target.name,
               createdAt: new Date().toISOString(),
               category: type === 'shanghui'?  this.state.shFileCategory:'cishan'? this.state.csFileCategory: this.state.xyFileCategory
               
            })
            updateConfig(type === 'shanghui'? 'SHANGHUI_CONFIG': 'cishan'? "GONGYI_CONFIG": "XUEYUAN_CONFIG",JSON.stringify(updatedConfig),'-')
    
        }
        const handleUpdateFileConfig = async(index,type) =>{
            const updatedConfig = type === 'shanghui'? [...this.state.shanghuiConfig]:'cishan'? [...this.state.cishanConfig]: [...this.state.shangxueyuanConfig];
            const user = JSON.parse(localStorage.getItem('authUser'));
            let adImage = ''
            const target = type === 'shanghui'?  this.state.updateSHFile[index]: 'cishan'? this.state.updateCSFile[index]:this.state.updateXYFile[index];
            if(target){
                var data = new FormData();
                data.append('Name', `AD-FILE-${new Date().getTime()}`);
                data.append('Images', target);
                data.append('Description', target.name);
                const config = {
                    headers: { Authorization: `Bearer ${user.token}` },              
                };
                const response = await axios.post(API+"/Config/media", data, config)
                adImage = response.data.data.value
            }else{
                alert("请添加图片！")
                return
            }
            updatedConfig[index]={
                ...updatedConfig[index],
               imageUrl: adImage,
               name: target.name,
               createdAt: new Date().toISOString()
               
            }
            updateConfig(type === 'shanghui'? 'SHANGHUI_CONFIG':'cishan'? "GONGYI_CONFIG":"XUEYUAN_CONFIG",JSON.stringify(updatedConfig),'-')
    
        }
       
        const handleAddBankConfig = async() =>{
            const updatedConfig = [...this.state.bankConfig];
            const user = JSON.parse(localStorage.getItem('authUser'));
            let qrcode = ''
            if(this.state.qrcode){
                var data = new FormData();
                data.append('Name', `QRCODE-${new Date().getTime()}`);
                data.append('Images', this.state.qrcode);
                data.append('Description', this.state.qrcode.name);
                const config = {
                    headers: { Authorization: `Bearer ${user.token}` },              
                };
                const response = await axios.post(API+"/Config/media", data, config)
                qrcode = response.data.data.value
            }
            updatedConfig.push({
                area:this.state.area,
                accountName:this.state.accountName,
                accountNumber: this.state.accountNumber,
                bank:this.state.bank,
                bsb:this.state.bsb,
                accountType: this.state.accountType,
                swiftCode: this.state.swiftCode, 
                branch: this.state.branch, 
                qrcode: qrcode
            })
            updateConfig('BANK_DETAIL_CONFIG',JSON.stringify(updatedConfig),'-')
    
        }

        const handleAddADConfig = async() =>{
            const updatedConfig = [...this.state.adConfig];
            const user = JSON.parse(localStorage.getItem('authUser'));
            let adImage = ''
            if(this.state.adImage){
                var data = new FormData();
                data.append('Name', `AD-IMAGE-${new Date().getTime()}`);
                data.append('Images', this.state.adImage);
                data.append('Description', this.state.adImage.name);
                const config = {
                    headers: { Authorization: `Bearer ${user.token}` },              
                };
                const response = await axios.post(API+"/Config/media", data, config)
                adImage = response.data.data.value
            }
            updatedConfig.push({
               imageUrl: adImage,
               
            })
            updateConfig('AD_CONFIG',JSON.stringify(updatedConfig),'-')
    
        }
        function updateConfig(name, newValue, description){
            const user = JSON.parse(localStorage.getItem('authUser'));
            const config = {
                headers: { Authorization: `Bearer ${user.token}` }
            };
            const body = {
                name: name,
                value: newValue,
                description: description
            };
            if (window.confirm("是否确定更新"+description)) {
                axios.put(API+"/Config", body, config).then(response => {
                    if (response.status === 400 || response.status === 500)
                        throw response.data;
                    window.location.reload();
                }).catch(err => {
                    throw err[1];
                });
            } else {

            }
        }
        const FormKey = (keyName, value, description) => {
            let inputValue = null;
            return(
                (keyName !== "BONUS_LOGIC" && keyName !== "BANK_DETAIL_CONFIG")?
                <FormGroup row>
                    <Label htmlFor={keyName} className="col-md-2 col-form-label">{description}</Label>
                    <Col md={6} style={{display: 'flex'}}>
                        <Label htmlFor={keyName} className="col-md-2 col-form-label">{value}</Label>
                        <Input className="form-control" type="text" value={inputValue} onChange={(event) => inputValue = event.target.value} id={keyName} />
                    </Col>
                    <Col md={4}>
                        <Button
                            type="button"
                            onClick={() => updateConfig(keyName, inputValue, description)}
                            className="waves-effect"
                        >
                            提交修改
                        </Button>
                    </Col>
                </FormGroup>
                :null
            );
        }
        const BonusLogic = (keyName, value, description) => {
          if (keyName === "BONUS_LOGIC"){
            let tb = convertConfigToArray(JSON.parse(value));
            return(
              keyName === "BONUS_LOGIC" ?
                <FormGroup row>
                  <Label htmlFor={keyName} className="col-md-2 col-form-label">{description}</Label>
                  <Col md={6} style={{display: 'flex'}}>
                    {/* <Label htmlFor={keyName} className="col-form-label">{value}</Label> */}
                    {/* <Input className="form-control" type="text" value={inputValue} onChange={(event) => inputValue = event.target.value} id={keyName} /> */}
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={tb}
                        columns={columns}
                        cellEdit={cellEditFactory({
                          mode: "click",
                          beforeSaveCell(oldValue, newValue, row, column, done) {
                            setTimeout(() => {
                              if (window.confirm('是否更改？')) {
                                done(); // contine to save the changes
                                updateConfig(keyName, JSON.stringify(convertConfigToObject(tb)), description);
                              } else {
                                done(false); // reject the changes
                              }
                            }, 0);
                            return { async: true };
                          }
                        })}
                      />
                    </div>
                  </Col>
                </FormGroup>
                :null
            );
          }

        }
        console.log(this.state.adImage)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">系统设置</h4>
                                        <p className="card-title-desc">通过提交下列表格修改系统参数。</p>
                                        {
                                            this.state.systemConfig ?
                                            this.state.systemConfig.map((item) => FormKey(item.name, item.value, item.description))
                                            :
                                            null
                                        }
                                        <p className="card-title-desc">通过提交下列表格修改衡量点产生逻辑。</p>
                                        <p className="card-title-desc">点击表格里面的参数，选中之后会出现输入框，输入对应的数值之后，按下回车键“enter”确认。</p>
                                        {
                                            this.state.systemConfig ?
                                            this.state.systemConfig.map((item) => BonusLogic(item.name, item.value, item.description))
                                            :
                                            null
                                        }
                                        <h4>银行设置</h4>
                                        <div style={{width:'100%',overflow:'auto'}}>
                                        <table style={{overflow:'auto'}} className={'table table-bordered'}>
                                            <tr>
                                                <th><p>地区</p></th>
                                                <th><p>银行名</p></th>
                                                <th><p>支付方式</p></th>
                                                <th><p>账户名</p></th>
                                                <th><p>bsb</p></th>
                                                <th><p>账号</p></th>
                                                <th><p>支行</p></th>
                                                <th><p>Swift Code</p></th>  
                                                <th><p>二维码</p></th>          
                                                <th><p>操作</p></th>     
                                            </tr>
                                            <tbody>
                                                {this.state.bankConfig.map(
                                                    (n,i)=>{
                                                        return (
                                                            <tr>
                                                                <th><p>{n.area}</p></th>
                                                                <th><p>{n.bank}</p></th>
                                                                <th><p>{n.accountType}</p></th>
                                                                <th><p>{n.accountName}</p></th>
                                                                <th><p>{n.bsb}</p></th>
                                                                <th><p>{n.accountNumber}</p></th>
                                                                <th><p>{n.branch}</p></th>
                                                                <th><p>{n.swiftCode}</p></th>
                                                                <th>{n.qrcode&&<a href={n.qrcode}> <img style={{width:50,height:50}} src={n.qrcode}/></a>}</th>
                                                                
                                                                <th><button onClick={()=>handleDeleteBankConfig(i)}>删除</button></th>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                                <tr>
                                                                <th><input value={this.state.area} onChange={(e)=>this.setState({area:e.target.value})}/></th>
                                                                <th><input value={this.state.bank} onChange={(e)=>this.setState({bank:e.target.value})}/></th>
                                                                <th><input value={this.state.accountType} onChange={(e)=>this.setState({accountType:e.target.value})}/></th>
                                                                <th><input value={this.state.accountName} onChange={(e)=>this.setState({accountName:e.target.value})}/></th>
                                                                <th><input value={this.state.bsb} onChange={(e)=>this.setState({bsb:e.target.value})}/></th>
                                                                <th><input value={this.state.accountNumber} onChange={(e)=>this.setState({accountNumber:e.target.value})}/></th>
                                                                <th><input value={this.state.branch} onChange={(e)=>this.setState({branch:e.target.value})}/></th>
                                                                <th><input value={this.state.swiftCode} onChange={(e)=>this.setState({swiftCode:e.target.value})}/></th>
                                                                <th><input type="file" accept="image/*"   onChange={(e)=>this.setState({qrcode:e.target.files[0]})}/></th>
                                                                <th><button onClick={handleAddBankConfig}>添加</button></th>
                                                            </tr>
                                            </tbody>
                                            </table>
                                            </div>
                                            <h4>广告位设置</h4>
                                        <div style={{maxWidth:'100%',overflow:'auto'}}>
                                        <table style={{overflow:'auto'}} className={'table table-bordered'}>
                                        <tr>
                                                <th><p>位置</p></th>
                                                <th><p>预览</p></th>
                                                <th><p>修改</p></th>
                                                <th><p>操作</p></th>
                                                </tr> 
                                                <tbody>
                                                    {this.state.adConfig?.map((n,i)=>{
                                                        return( <tr>
                                                            <th><p>{i+1}</p></th>
                                                            <th><img src={n.imageUrl} style={{width:50,height:50}}/></th>
                                                            <th><input type="file" accept="image/*"   onChange={(e)=>this.setState({updateAdImage:{[i]:e.target.files[0]}})}/></th>
                                                            <th><button onClick={()=>{handleUpdateAdConfig(i)}}>更改</button><button onClick={()=>handleDeleteAdConfig(i)}>删除</button></th>
                                                            </tr> )
                                                    })}
                                                <tr>
                                                <th><p></p></th>
                                                <th>{this.state.adImage&&<img src={URL.createObjectURL(this.state.adImage)} style={{width:50,height:50}}/>}</th>
                                                <th><input type="file" accept="image/*"   onChange={(e)=>this.setState({adImage:e.target.files[0]})}/></th>
                                                <th><button onClick={handleAddADConfig}>添加</button></th>
                                                </tr> 
                                                    </tbody>        
                                        </table>
                                        </div>
                                        <h4>商会文件设置</h4>
                                        <div style={{maxWidth:'100%',overflow:'auto'}}>
                                        <table style={{overflow:'auto'}} className={'table table-bordered'}>
                                        <tr>
                                                <th><p>位置</p></th>
                                                <th><p>标题</p></th>
                                                <th><p>类别</p></th>
                                                <th><p>修改</p></th>
                                                <th><p>操作</p></th>
                                                </tr> 
                                                <tbody>
                                                    {this.state.shanghuiConfig?.map((n,i)=>{
                                                        return( <tr>
                                                            <th><p>{i+1}</p></th>
                                                            <th>{n.name}</th>
                                                            <th>{n.category}</th>
                                                            <th><input type="file" accept="*"   onChange={(e)=>this.setState({updateSHFile:{[i]:e.target.files[0]}})}/></th>
                                                            <th><button onClick={()=>{handleUpdateFileConfig(i,'shanghui')}}>更改</button><button onClick={()=>handleDeleteFileConfig(i,'shanghui')}>删除</button></th>
                                                            </tr> )
                                                    })}
                                                <tr>
                                                <th><p></p></th>
                                                <th>{this.state.shFile?.name}</th>
                                                <th><input value={this.state.shFileCategory} onChange={(e)=>this.setState({shFileCategory:e.target.value})}/></th>
                                                <th><input type="file" accept="*"   onChange={(e)=>this.setState({shFile:e.target.files[0]})}/></th>
                                                <th><button onClick={()=>handleAddFileConfig('shanghui')}>添加</button></th>
                                                </tr> 
                                                    </tbody>        
                                        </table>
                                        </div>
                                        <h4>商学院文件设置</h4>
                                        <div style={{maxWidth:'100%',overflow:'auto'}}>
                                        <table style={{overflow:'auto'}} className={'table table-bordered'}>
                                        <tr>
                                                <th><p>位置</p></th>
                                                <th><p>标题</p></th>
                                                <th><p>类别</p></th>
                                                <th><p>修改</p></th>
                                                <th><p>操作</p></th>
                                                </tr> 
                                                <tbody>
                                                    {this.state.shangxueyuanConfig?.map((n,i)=>{
                                                        return( <tr>
                                                            <th><p>{i+1}</p></th>
                                                            <th>{n.name}</th>
                                                            <th>{n.category}</th>
                                                            <th><input type="file" accept="*"   onChange={(e)=>this.setState({updateXYFile:{[i]:e.target.files[0]}})}/></th>
                                                            <th><button onClick={()=>{handleUpdateFileConfig(i,'shangxueyuan')}}>更改</button><button onClick={()=>handleDeleteFileConfig(i,'shangxueyuan')}>删除</button></th>
                                                            </tr> )
                                                    })}
                                                <tr>
                                                <th><p></p></th>
                                                <th>{this.state.xyFile?.name}</th>
                                                <th><input value={this.state.xyFileCategory} onChange={(e)=>this.setState({xyFileCategory:e.target.value})}/></th>
                                                <th><input type="file" accept="*"   onChange={(e)=>this.setState({xyFile:e.target.files[0]})}/></th>
                                                <th><button onClick={()=>handleAddFileConfig('shangxueyuan')}>添加</button></th>
                                                </tr> 
                                                    </tbody>        
                                        </table>
                                        </div>
                                        <h4>慈善专区设置</h4>
                                        <div style={{maxWidth:'100%',overflow:'auto'}}>
                                        <table style={{overflow:'auto'}} className={'table table-bordered'}>
                                        <tr>
                                                <th><p>位置</p></th>
                                                <th><p>标题</p></th>
                                                <th><p>类别</p></th>
                                                <th><p>修改</p></th>
                                                <th><p>操作</p></th>
                                                </tr> 
                                                <tbody>
                                                    {this.state.cishanConfig?.map((n,i)=>{
                                                        return( <tr>
                                                            <th><p>{i+1}</p></th>
                                                            <th>{n.name}</th>
                                                            <th>{n.category}</th>
                                                            <th><input type="file" accept="*"   onChange={(e)=>this.setState({updateCSFile:{[i]:e.target.files[0]}})}/></th>
                                                            <th><button onClick={()=>{handleUpdateFileConfig(i,'cishan')}}>更改</button><button onClick={()=>handleDeleteFileConfig(i,'cishan')}>删除</button></th>
                                                            </tr> )
                                                    })}
                                                <tr>
                                                <th><p></p></th>
                                                <th>{this.state.csFile?.name}</th>
                                                <th><input value={this.state.csFileCategory} onChange={(e)=>this.setState({csFileCategory:e.target.value})}/></th>
                                                <th><input type="file" accept="*"   onChange={(e)=>this.setState({csFile:e.target.files[0]})}/></th>
                                                <th><button onClick={()=>handleAddFileConfig('cishan')}>添加</button></th>
                                                </tr> 
                                                    </tbody>        
                                        </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;
